<template>
  <div v-if="visible">
    <RevButtonBase
      v-for="button in buttons"
      :key="button.key"
      target="_blank"
      :to="button.url"
    >
      <RevIllustration
        :alt="button.alt"
        :class="button.class"
        :height="button.height"
        :src="button.img"
        :width="button.width"
      />
    </RevButtonBase>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import {
  APPLE_STORE_REDIRECT_URL,
  APPSFLYER_CAMPAIGN,
  APPSFLYER_MEDIA_SOURCE,
  GOOGLE_STORE_REDIRECT_URL,
  STORE_COUNTRIES,
  Store,
} from './AppStoreButtons.constant'

// Note that before registering new URLs to this configuration, you should
// also download and store associated assets in `public/img/social`.

// assets may be found here: https://www.figma.com/file/jC2mtHRgj5YEWRLSQYfKHq/04.-Assets?node-id=1%3A852

type AppStoreButtonsProps = {
  campaign?: string
}

const props = defineProps<AppStoreButtonsProps>()

const { market } = useMarketplace()
const runtimeConfig = useRuntimeConfig()

const { APPSFLYER_BASE_URL } = runtimeConfig.public

const storesBaseUrl = `${APPSFLYER_BASE_URL}?pid=${APPSFLYER_MEDIA_SOURCE}&c=${APPSFLYER_CAMPAIGN}`

function getStoreBaseUrl(store: Store) {
  if (store === Store.APPLE) {
    return `${storesBaseUrl}&af_r=${APPLE_STORE_REDIRECT_URL}`
  }

  return `${storesBaseUrl}&af_r=${GOOGLE_STORE_REDIRECT_URL}`
}

function getStoreUrl(store: Store) {
  const storeBaseUrl = getStoreBaseUrl(store)

  return `${storeBaseUrl}&af_ad=${market.countryCode.toLowerCase()}&af_adset=${
    props.campaign
  }`
}

const buttons = computed(() => {
  if (!STORE_COUNTRIES.includes(market.countryCode)) {
    return []
  }

  return [
    {
      key: 'google-play',
      url: getStoreUrl(Store.GOOGLE),
      img: `/img/socials/${market.countryCode}/google-play.svg`,
      alt: 'Google Play',
      class: 'inline-block h-[4rem]',
      width: 135,
      height: 40,
    },
    {
      key: 'app-store',
      url: getStoreUrl(Store.APPLE),
      img: `/img/socials/${market.countryCode}/apple-store.svg`,
      alt: 'Apple Store',
      class: 'inline-block h-[4rem] ml-2',
      width: 127,
      height: 40,
    },
  ].filter((button) => button.url)
})

const visible = computed(() => !isEmpty(buttons.value))
</script>
