export default {
  confirmationAppsTitle: {
    id: 'buyback_recycling_confirmation_apps_title',
    defaultMessage: 'Trade-in = trade-win',
  },
  confirmationAppsDescription: {
    id: 'buyback_recycling_confirmation_apps_description',
    defaultMessage: 'Watch your old tech turn into cash anytime, anywhere.',
  },
}
